






























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { authorizationService } from "@/shared/services/AuthorizationService";
import { UserProfileItem } from "@/components/header/UserProfile/UserProfileItem";
import { JwtService } from "@/shared/services/JwtService";
import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
@Component({
  components: {},
})
export default class UserProfile extends Vue {
  public items: UserProfileItem[] = [
    {
      icon: "fad fa-sign-out-alt primary--text fa-lg ma-2",
      text: "",
      i18n: "Cerrar sesión",
      id: 0,
    },
    {
      icon: "fad fa-user-circle primary--text fa-lg ma-2",
      text: "",
      i18n: "Ver perfil",
      id: 1,
    },
  ];
  public menu: boolean = false;
  public image_default = false;
  public refre = false;
  public date = 2;

  /**
   * get getImagen
   */
  public get getImagen() {
    return (
      API.webApiBaseImages +
      JwtService.jwtDecode().nameid +
      ".png?rnd=" +
      this.date
    );
  }
  public get userName() {
    return authorizationService.userName();
  }

  public logout() {
    authorizationService.logout();
    this.$router.push("/login");
  }

  public opcion(item: UserProfileItem) {
    if (item.id === 0) {
      this.logout();
    } else if (item.id === 1) {
      this.$router.push({ name: RouterNames.mi_perfil });
    }
    this.menu = false;
  }
  public imageLoadError(event: any) {
    this.image_default = true;
  }
}
